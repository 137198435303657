const BASE_URL = process.env.SNOWPACK_PUBLIC_API_ENDPOINT || '';

export async function getQBInvoicesRecId(id) {
  const res = await fetch(
    `${BASE_URL}/quickBooks/getQBInvoicesRecId?recId=${id}`,
  );
  if (!res.ok) {
    throw new Error('getQBInvoicesReqId request error');
  }
  const { result } = await res.json();
  return result;
}

export async function getHostingLastRunTime() {
  const res = await fetch(`${BASE_URL}/hosting/getHostingLastRunTime`);
  if (!res.ok) {
    throw new Error('getQBInvoicesReqId request error');
  }
  const { result } = await res.json();
  return result;
}
